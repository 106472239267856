import * as React from "react";

import { Layout } from "components";
import {
  SettlementFeatures,
  SettlementsExploreSection,
  SettlementsPageHeader,
} from "templates/solutions/settlements";

const SettlementsPage = () => (
  <Layout title="Settlements">
    <SettlementsPageHeader />
    <SettlementFeatures />
    <SettlementsExploreSection />
  </Layout>
);

export default SettlementsPage;
