import React from "react";
import { ExploreCard } from "components";
import { StaticImage } from "gatsby-plugin-image";

const SettlementsExploreSection = () => {
  return (
    <ExploreCard
      title="Bring your idea to life"
      description="All products/services were once ideas, but not all ideas make it to becoming products and services. We want to work with founders and business owners to ensure that their thoughts and ideas make it through the lifecycle."
      buttonText="Build with us"
      image={
        <StaticImage
          src="../../../../assets/images/jpegs/settlements/simple-banking-infrastructure.jpeg"
          alt="Simple Banking Infrastructure"
        />
      }
    />
  );
};

export { SettlementsExploreSection };
