import { Br, PageHeader } from "components";
import React from "react";

const SettlementsPageHeader = () => {
  return (
    <PageHeader
      title={
        <>
          <span className="text-primary">Simplify the payment</span> experience{" "}
          <Br on="desktop" />
          for your customers.
        </>
      }
      subheading={
        <>
          Our integration can help you access seamless settlements on
          transactions <Br on="desktop" />
          across the different financial institutions in Nigeria.
        </>
      }
      button={{ text: "Get Started", to: "/get-demo" }}
    />
  );
};

export { SettlementsPageHeader };
