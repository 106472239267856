import React from "react";
import { PageFeatures } from "components";
import { StaticImage } from "gatsby-plugin-image";

const SettlementFeatures = () => {
  return (
    <div>
      <PageFeatures
        features={[
          "No hidden Charges",
          "24/7 Access to Support lines",
          "Multiple use cases",
          "Seamless experience for your customers",
        ]}
        image={
          <StaticImage
            src="../../../../assets/images/jpegs/settlements/features.jpeg"
            alt="features"
          />
        }
      />
    </div>
  );
};

export { SettlementFeatures };
